'use client';

import type { ComponentPropsWithRef, ReactNode } from 'react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { Img } from '@/components/atoms/Img';
import styles from './index.module.scss';

export interface LocationMapSectionGoogleMapProps extends ComponentPropsWithRef<'div'> {
  readonly apiKey: string;
  readonly image: string;
  readonly landingUrl: string;
}

export function LocationMapSectionGoogleMap({
  apiKey,
  image,
  landingUrl,
}: LocationMapSectionGoogleMapProps): ReactNode {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);

  const mapRef = useRef<HTMLDivElement>(null);

  const location = {
    centerLatLng: {
      lat: 52.450355,
      lng: -1.592563,
    },
    zoom: 6.8,
  };

  const onScriptLoad = useCallback(async () => {
    if (typeof window === 'undefined') return;
    try {
      const response = await fetch(`${landingUrl}/envelope-coverage.json`);
      const coverage = await response.json();
      if (mapRef.current instanceof Element) {
        const map = new window.google.maps.Map(mapRef.current, {
          center: location.centerLatLng,
          zoom: location.zoom,
          mapTypeId: 'roadmap',
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
        });

        map.data.setStyle(() => ({
          fillColor: '#58b0ff',
          fillOpacity: 0.4,
          strokeWeight: 1,
        }));

        if (!isEmpty(coverage)) map.data.addGeoJson(coverage);
      } else {
        throw new Error('mapRef is not a valid element.');
      }
    } catch (error) {
      console.error(error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!apiKey) return;

    if (window.google) {
      onScriptLoad();
      return;
    }

    if (window.google_map_script_tag) {
      const waitScriptLoad = setInterval(() => {
        if (window.google) {
          onScriptLoad();
          clearInterval(waitScriptLoad);
        }
      }, 300);
      return;
    }

    window.google_map_script_tag = true;
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `https://maps.google.com/maps/api/js?key=${apiKey}`;
    script.async = true;
    document.head.appendChild(script);
    script.addEventListener('load', onScriptLoad);
    script.addEventListener('error', () => console.error('Google Maps script failed to load.'));
  }, [apiKey, onScriptLoad]);

  if (isError) return null;

  return (
    <div className={classNames('GoogleMap', styles.container)}>
      {isLoading && (
        <div className={styles.loader} style={{ background: `url(${image}) no-repeat center` }}>
          <Img
            provider="cloudinary"
            src="/icons/icon_spinner_ua5yua.svg"
            alt="spinner"
            height={200}
            width={200}
          />
        </div>
      )}
      <div ref={mapRef} className={styles.GoogleMap} style={{ display: isLoading ? 'none' : 'block' }} />
    </div>
  );
}

LocationMapSectionGoogleMap.displayName = 'LocationMapSectionGoogleMap';
