import type { ComponentPropsWithoutRef } from 'react';
import React from 'react';
import classNames from 'classnames';
import { Img } from '@/components/atoms/Img';

import styles from './index.module.scss';

export interface FixieInfoProps extends ComponentPropsWithoutRef<'div'> {
  readonly showFixie?: boolean;
}

/**
 * @design https://www.figma.com/file/70eYjqTHPKhPnFZ5TbnGo8/Design-System?type=design&node-id=1069-5898&mode=design
 */
export function FixieInfo({ className, children, showFixie, ...props }: FixieInfoProps): JSX.Element {
  return (
    <div className={classNames(FixieInfo.displayName, styles.component, className)} {...props}>
      {showFixie && (
        <Img
          provider="cloudinary"
          src="/icons/fixie__blue_bg_e0iine.png"
          alt="Fixie"
          width={40}
          height={40}
        />
      )}
      {children}
    </div>
  );
}
FixieInfo.displayName = 'FixieInfo';
