/**
 * This file was created for so we can do an AB test on the HeroSection and we have to add 'use client'
 * @TODO remove 'use client' after AB test HERO_CND_AVAILABLE_UK is over, consolidate components back into SC
 */

'use client';

import type { ReactNode } from 'react';
import classNames from 'classnames';
import { Locale } from '@fixter/i18n';
import { ActiveExperiments } from '$util/abTest/experiments';
import { useActiveExperiment } from '$util/abTest';
import type { ProcessedCmsContentfulImage } from '$cms/contentful.types';
import { Row } from '$src-components/atoms/Grid';
import { GrandLabel } from '@/components/atoms/GrandLabel';
import { PartOfRenault } from '../PartOfRenault';
import styles from './index.module.scss';

export function WithABSubheading({
  subheading,
  locale,
}: {
  subheading: ReactNode;
  locale: Locale;
}): ReactNode {
  const isABHeroCNDAvailableUK = useActiveExperiment(ActiveExperiments.HeroCNDAvailableUK, 'B');

  return (
    (subheading || isABHeroCNDAvailableUK) && (
      <h2 className={styles.hero__subheading}>
        {locale !== Locale.FR_FR && isABHeroCNDAvailableUK
          ? 'We collect, we fix & we return your car' // hard coded text for AB test
          : subheading}
      </h2>
    )
  );
}

export function WithABGrandLabel({
  grandLabel,
  locale,
}: {
  grandLabel: ReactNode;
  locale: Locale;
}): ReactNode {
  const isABHeroCNDAvailableUK = useActiveExperiment(ActiveExperiments.HeroCNDAvailableUK, 'B');

  return (
    ((locale === Locale.FR_FR && grandLabel) || isABHeroCNDAvailableUK) && (
      <GrandLabel className={styles.grandLabel}>{grandLabel}</GrandLabel>
    )
  );
}

export function WithABPartOfRenault({
  partOfRenaultText,
  partOfRenaultLogo,
  locale,
}: {
  partOfRenaultText: string;
  partOfRenaultLogo: Omit<ProcessedCmsContentfulImage, '__typename'>;
  locale: Locale;
}): ReactNode {
  const isABHeroCNDAvailableUK = useActiveExperiment(ActiveExperiments.HeroCNDAvailableUK, 'B');
  return (
    (locale === Locale.FR_FR || isABHeroCNDAvailableUK) && (
      <PartOfRenault
        className={styles.renault}
        layout="horizontal"
        preload
        label={partOfRenaultText}
        logo={partOfRenaultLogo}
      />
    )
  );
}

export function WithABGrandLabelOrPartOfRenault({
  grandLabel,
  partOfRenaultText,
  partOfRenaultLogo,
  locale,
}: {
  grandLabel?: ReactNode;
  partOfRenaultText?: string;
  partOfRenaultLogo?: Omit<ProcessedCmsContentfulImage, '__typename'>;
  locale: Locale;
}): ReactNode {
  const isABHeroCNDAvailableUK = useActiveExperiment(ActiveExperiments.HeroCNDAvailableUK, 'B');
  return (locale === Locale.FR_FR && grandLabel) || isABHeroCNDAvailableUK ? (
    <GrandLabel className={styles.grandLabel}>{grandLabel}</GrandLabel>
  ) : (
    <PartOfRenault
      className={styles.partOfRenault}
      layout="horizontal"
      preload
      label={partOfRenaultText}
      logo={partOfRenaultLogo}
    />
  );
}

export function WithABRow({
  locale,
  className,
  children,
  ...props
}: {
  locale: Locale;
  className?: string;
  children: ReactNode;
}): ReactNode {
  const isABHeroCNDAvailableUK = useActiveExperiment(ActiveExperiments.HeroCNDAvailableUK, 'B');
  return (
    <Row
      className={classNames(
        'HeroSection',
        styles.row,
        styles.topAdjustment,
        locale === Locale.FR_FR || isABHeroCNDAvailableUK
          ? styles.heroCNDAvailable
          : styles.heroWithoutCNDAvailable,
        className
      )}
      {...props}
    >
      {children}
    </Row>
  );
}
