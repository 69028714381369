import type { ComponentPropsWithoutRef } from 'react';
import React from 'react';
import classNames from 'classnames';
import { Icon } from '../Icon';

import styles from './index.module.scss';

export type GrandLabelProps = ComponentPropsWithoutRef<'div'>;

export function GrandLabel({ className, children, ...props }: GrandLabelProps): JSX.Element {
  return (
    <div className={classNames(GrandLabel.displayName, styles.component, className)} {...props}>
      <Icon
        id="general/check-circle-broken"
        legacy={false}
        width={24}
        height={24}
        color={styles.colorYellow200}
        className={styles.icon}
      />
      <span>{children}</span>
    </div>
  );
}
GrandLabel.displayName = 'GrandLabel';
